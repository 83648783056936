import { isJson } from 'utils/json'

type HTTPMethods = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH'

export interface PromiseWrapperOptions {
  contentType?: string
  withCredentials?: boolean
}

export function promiseWrapper<T, P>(
  url: string,
  method: HTTPMethods,
  data?: P,
  options?: PromiseWrapperOptions,
  abortSignal?: AbortSignal,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url)

    if (abortSignal) {
      abortSignal.onabort = () => {
        xhr.abort()
        reject(new DOMException('Request cancelled', 'AbortError'))
      }
    }

    xhr.setRequestHeader('Content-type', options?.contentType || 'application/json')
    xhr.onload = () => {
      if ([200, 201, 202].includes(xhr.status)) {
        resolve(xhr.response && isJson(xhr.response) ? JSON.parse(xhr.response) : xhr.response)
      } else {
        reject(xhr)
      }
    }
    if (options?.withCredentials) {
      xhr.withCredentials = true
    }
    xhr.onerror = () => reject(xhr.statusText)
    xhr.send(data ? JSON.stringify(data) : null)
  })
}
