export default {
  calculator: {
    buyLabelName: 'i have',
    sellLabelName: 'i will receive',
    currentRate: 'Current rate:',
    continueButton: 'Proceed to exchange',
    miniChart: 'The mini-chart shows the fluctuations of the average rate over the last 7 days.',
    sellTooltip:
      'This is a sales rate - we sell you {{currency}} for PLN {{rate}}. The lower the rate, the more beneficial it is for you. Automatic update every 10 seconds.',
    buyTooltip:
      'This is a buying rate - we buy {{currency}} from you for PLN {{rate}}. The higher the rate, the more beneficial it is for you. Automatic update every 10 seconds.',
  },
  newsletter: {
    alreadySubscribed: 'Your email is already in our newsletter.',
    error: 'Something went wrong. Try again later.',
  },
  preactSelect: {
    search: 'Search...',
  },
  frontpageExitPopup: {
    accountExists: 'It looks like you already have an account. We send a discount to new customers.',
    error: 'An error has occurred',
  },
  emailValidator: {
    requiredField: 'This field must be checked',
    notEmptyField: 'This field cannot be empty',
    minValue: 'The manimum value is',
    maxValue: 'The maximum value is',
    incorrectEmail: 'Please provide valid email address',
    toShortPassowrd: 'Password is too short',
  },
  currencyAlert: {
    isLower: 'is lower',
    isHigher: 'is higher',
    mustBeLessThan: 'The rate must be a number less than 99.9999',
    alertAlreadyExists: 'A rate alert for the specified rate already exists',
    maxAlerts: 'You cannot subscribe to more alerts. (max. 10)',
    error: 'Something went wrong, please try again in a moment.',
    alertHash: 'currency-alert',
  },
  ratesUpdate: {
    update: 'Course update in progress...',
    success: 'Next update in',
    error: 'Failed to download data. Retry in',
    timeUnit: 'seconds.',
  },
  currencies: {
    // AED: 'UAE dirham',
    ARS: 'Argentine peso',
    AUD: 'Australian dollar',
    // BDT: 'Bangladeshi taka',
    BGN: 'Bulgarian lev',
    BRL: 'Brazilian real',
    CAD: 'Canadian dollar',
    CHF: 'Swiss franc',
    CLP: 'Chilean peso',
    // CNY: 'Chinese yuan',
    COP: 'Colombian peso',
    CZK: 'Czech koruna',
    DKK: 'Danish crown',
    EGP: 'Egyptian Pound',
    EUR: 'Euro',
    FJD: 'Fijian Dollar',
    GBP: 'British pound',
    GHS: 'Ghanaian Cedi',
    HKD: 'Hong Kong dollar',
    HUF: 'Hungarian forint',
    ILS: 'Israeli new shekel',
    // INR: 'Indian Rupee',
    JOD: 'Jordanian dinar',
    JPY: 'Japanese yen',
    KWD: 'Kuwaiti Dinar',
    LSL: 'Lesotho loti',
    MAD: 'Moroccan dirham',
    MXN: 'Mexican peso',
    NOK: 'Norwegian krone',
    NPR: 'Nepalese rupee',
    NZD: 'New Zealand dollar',
    OMR: 'Rial Omani',
    PEN: 'Peruvian sol',
    PGK: 'Papua New Guinea Kina',
    PLN: 'Polish zloty',
    RON: 'Romanian leu',
    RUB: 'Russian ruble',
    SAR: 'Saudi riyal',
    SEK: 'Swedish krona',
    SGD: 'Singapore dollar',
    SZL: 'Swazi lilangeni',
    // THB: 'Thai baht',
    TRY: 'Turkish lira',
    USD: 'US Dollar',
    // UYU: 'Uruguayan peso',
    WST: 'Samoan Tala',
    ZAR: 'South African rand',
  },
  currenciesDopelniacz: {
    AED: 'UAE dirham',
    ARS: 'Argentine peso',
    AUD: 'Australian dollar',
    BDT: 'Bangladeshi taka',
    BGN: 'Bulgarian lev',
    BRL: 'Brazilian real',
    CAD: 'Canadian dollar',
    CHF: 'Swiss franc',
    CLP: 'Chilean peso',
    CNY: 'Chinese yuan',
    COP: 'Colombian peso',
    CZK: 'Czech koruna',
    DKK: 'Danish crown',
    EGP: 'Egyptian Pound',
    EUR: 'Euro',
    FJD: 'Fijian Dollar',
    GBP: 'British pound',
    GHS: 'Ghanaian Cedi',
    HKD: 'Hong Kong dollar',
    HUF: 'Hungarian forint',
    ILS: 'Israeli new shekel',
    INR: 'Indian Rupee',
    JOD: 'Jordanian dinar',
    JPY: 'Japanese yen',
    KWD: 'Kuwaiti Dinar',
    LSL: 'Lesotho loti',
    MAD: 'Moroccan dirham',
    MXN: 'Mexican peso',
    NOK: 'Norwegian krone',
    NPR: 'Nepalese rupee',
    NZD: 'New Zealand dollar',
    OMR: 'Rial Omani',
    PEN: 'Peruvian sol',
    PGK: 'Papua New Guinea Kina',
    PLN: 'Polish zloty',
    RON: 'Romanian leu',
    RUB: 'Russian ruble',
    SAR: 'Saudi riyal',
    SEK: 'Swedish krona',
    SGD: 'Singapore dollar',
    SZL: 'Swazi lilangeni',
    THB: 'Thai baht',
    TRY: 'Turkish lira',
    USD: 'US Dollar',
    UYU: 'Uruguayan peso',
    WST: 'Samoan Tala',
    ZAR: 'South African rand',
  },
  chart: {
    time: 'Time',
    rate: 'Rate',
    opening: 'Opening',
    highest: 'Highest',
    lowest: 'Lowest',
    closing: 'Closing',
    monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
    dayNames: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  },
  landingForm: {
    show: 'Show',
    hide: 'Hide',
    requiredField: 'This field must be checked',
    notEmptyField: 'This field cannot be empty',
    incorrectEmail: 'Please provide valid email address',
    toShortPassowrd: 'Password is too short',
    invalidPromocode: 'Promotion available on this page has ended - register via the home page',
    emailAlreadyExists: 'This email cannot be used for registration',
    generalInvalid: 'Something went wrong, please try again in a moment',
    competitionFormThanks: 'Thank you for participating in the contest!',
  },
}
